import React from "react";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MappingTranslations } from "./types";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    marginLeft: theme.spacing(2),
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  dropDownButton: {
    maxWidth: "1em",
    "& svg": {
      height: "0.7em",
    },
  },
  popper: {
    zIndex: 2,
  },
}));

const SubmitButton: React.FC<{
  translations: MappingTranslations;
  saveJob: () => void;
  submitJob: () => void;
  isSubmitPopupOpen: boolean;
  setIsSubmitPopupOpen: (value: boolean) => void;
  enableSaving: boolean;
}> = ({
  translations,
  saveJob,
  submitJob,
  enableSaving,
  isSubmitPopupOpen,
  setIsSubmitPopupOpen,
}) => {
  const classes = useStyles();
  const submitButtonAnchor = React.useRef<HTMLDivElement>(null);

  return (
    <div>
      {enableSaving ? (
        <div>
          <ButtonGroup
            variant="contained"
            className={classes.buttonStyle}
            ref={submitButtonAnchor}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              data-testid="job-mappings-submit-button"
              className={classes.saveButton}
              onClick={submitJob}
            >
              {translations.submit}
            </Button>
            <Button
              className={`${classes.saveButton} ${classes.dropDownButton}`}
              data-testid="job-mappings-save-dropdown"
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsSubmitPopupOpen(true);
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popper
            className={classes.popper}
            open={isSubmitPopupOpen}
            anchorEl={submitButtonAnchor.current}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsSubmitPopupOpen(false);
                    }}
                  >
                    <MenuList>
                      <MenuItem
                        onClick={saveJob}
                        data-testid="job-mappings-save-button"
                      >
                        {translations.saveChange}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          data-testid="job-mappings-submit-button"
          className={`${classes.buttonStyle} ${classes.saveButton}`}
          onClick={submitJob}
        >
          {translations.submit}
        </Button>
      )}
    </div>
  );
};

export default SubmitButton;
