export const mappingOptions: { label: string; value?: string }[] = [
  {
    label: "fieldSelectorComponent.default",
    value: "select",
  },
  {
    label: "fieldSelectorComponent.companyInformation",
  },
  {
    label: "fieldSelectorComponent.safeNumber",
    value: "companyId",
  },
  {
    label: "fieldSelectorComponent.companyRegisteredNumber",
    value: "orgNumber",
  },
  {
    label: "fieldSelectorComponent.vatNumber",
    value: "vatNumber",
  },
  {
    label: "fieldSelectorComponent.taxCode",
    value: "taxCode",
  },
  {
    label: "fieldSelectorComponent.companyName",
    value: "name",
  },
  {
    label: "fieldSelectorComponent.tradingName",
    value: "tradingName",
  },
  {
    label: "fieldSelectorComponent.aliasName",
    value: "aliasName",
  },
  {
    label: "fieldSelectorComponent.previousName",
    value: "previousName",
  },
  {
    label: "fieldSelectorComponent.telephoneNumber",
    value: "phone",
  },
  {
    label: "fieldSelectorComponent.emailAddress",
    value: "email",
  },
  {
    label: "fieldSelectorComponent.companyAddress",
  },
  {
    label: "fieldSelectorComponent.addressLine1",
    value: "address1",
  },
  {
    label: "fieldSelectorComponent.addressLine2",
    value: "address2",
  },
  {
    label: "fieldSelectorComponent.addressLine3",
    value: "address3",
  },
  {
    label: "fieldSelectorComponent.addressLine5",
    value: "address5",
  },
  {
    label: "fieldSelectorComponent.town",
    value: "town",
  },
  {
    label: "fieldSelectorComponent.postcode",
    value: "postcode",
  },
  {
    label: "fieldSelectorComponent.region",
    value: "region",
  },
  {
    label: "fieldSelectorComponent.countryCode",
    value: "country",
  },
  {
    label: "fieldSelectorComponent.personalData",
  },
  {
    label: "fieldSelectorComponent.personalID",
    value: "personalID",
  },
  {
    label: "fieldSelectorComponent.personalLimit",
    value: "personalLimit",
  },
  {
    label: "fieldSelectorComponent.other",
    value: "other",
  },
];
