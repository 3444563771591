import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  // Link,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Alert } from "@material-ui/lab";
import FileInput from "../FileInput/FileInput";
import FormInput from "../FormInput/FormInput";
import { FileUploadFormProps } from "./types";
import CloudDownload from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSection: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    iconProps: {
      color: theme.palette.success.main,
      fontSize: "60px",
    },
    btnSubmit: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    btnWithTemplate: {
      height: "4rem",
      marginTop: "4rem",
    },
    alertMessage: {
      marginTop: theme.spacing(1),
    },
    label: {
      display: "block",
      fontWeight: 600,
    },
    cardContainer: {
      margin: theme.spacing(2, 0),
    },
  })
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractFormElements = (event: any, value: string) =>
  event?.target?.elements[value]?.value;

const FileUploadForm: React.FC<FileUploadFormProps> = ({
  onSubmit,
  defaultValues = {},
  error,
  isSubmitDisabled = false,
  textContent,
  fileInputProps,
  requried = true,
  masterTemplates,
  matchInformationFileDownload,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);

  const [headersPresent, setHeadersPresent] = useState(
    defaultValues.hasHeaderRow || false
  );

  const [fileName, setFileName] = useState(defaultValues.fileName || "");

  const [selectedMasterTemplateId, setSelectedMasterTemplateId] = useState("0");

  useEffect(() => {
    if (selectedMasterTemplateId !== "0") {
      setHeadersPresent(false);
    }
  }, [selectedMasterTemplateId]);

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();

        onSubmit({
          userId: extractFormElements(e, "user-id"),
          customerId: extractFormElements(e, "customer-id"),
          companyName: extractFormElements(e, "company-name"),
          contactName: extractFormElements(e, "contact-name"),
          contactTelephone: extractFormElements(e, "telephone"),
          contactEmail: extractFormElements(e, "email"),
          file: file,
          hasHeaderRow: e?.target?.elements["headers-present"]?.checked,
          fileName: extractFormElements(e, "file-name"),
          masterTemplateId: extractFormElements(e, "master-template"),
        });
      }}
    >
      <div className={classes.cardContainer}>
        <Card data-testid="contact-details">
          <Typography variant="h5">{textContent.formTitle}</Typography>
          <Grid container spacing={2} className={classes.formSection}>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.companyNameLabel}
                inputName={"company-name"}
                defaultValue={defaultValues.companyName}
                disabled={true}
                required={requried}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.customerIdLabel}
                inputName={"customer-id"}
                defaultValue={defaultValues.customerId?.toString()}
                disabled={true}
                required={requried}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.userIdLabel}
                inputName={"user-id"}
                defaultValue={defaultValues.userId?.toString()}
                disabled={true}
                required={requried}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.contactPersonLabel}
                inputName={"contact-name"}
                defaultValue={defaultValues.contactName}
                required={requried}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.contactTelehoneLabel}
                inputName={"telephone"}
                defaultValue={defaultValues.contactTelephone}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                label={textContent.contactEmailLabel}
                inputName={"email"}
                defaultValue={defaultValues.contactEmail}
                required={requried}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
      <div className={classes.cardContainer}>
        <Card data-testid="file-upload">
          <Grid container>
            <Grid item md={6}>
              <Typography variant={"h5"} component={"h3"} gutterBottom>
                {textContent.formSubtitle}
              </Typography>
            </Grid>
            <Grid item md={6}>
              {textContent.attachmentLinkName &&
                textContent.attachmentLinkName.length > 0 &&
                matchInformationFileDownload && (
                  <Button
                    onClick={matchInformationFileDownload}
                    data-testid="match-information-link"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#66687a",
                      fontSize: "1.3rem",
                      fontWeight: 500,
                      textDecoration: "underline",
                      cursor: "pointer",
                      float: "right",
                    }}
                    endIcon={<CloudDownload />}
                  >
                    {textContent.attachmentLinkName}
                  </Button>
                )}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.formSection}>
            <Grid item xs={12} md={6}>
              <Typography
                component="label"
                htmlFor="fileUpload"
                className={classes.label}
                gutterBottom
              >
                {textContent.uploadFileLabel}
              </Typography>
              <FileInput
                maxFileSizeBytes={10485760}
                acceptedFileTypes={[".csv", ".xls", ".xlsx"]}
                file={file}
                setFile={setFile}
                onFileChange={(file: File | null) =>
                  !fileName && file?.name ? setFileName(file.name) : null
                }
                id={"fileUpload"}
                textContent={{
                  errorFileTooLarge: "The file is too large.",
                  errorInvalidType: "The file extension is not supported.",
                  errorTooManyFiles: "Too many files provided",
                  selectedFileName: "Selected File",
                  selectedFileSize: "File Size",
                  uploadFileExtensions: "File extensions are limited to:",
                  uploadFileLimit: "File size is limited to:",
                  uploadInstructions: "Drop a file to attach",
                }}
                {...fileInputProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormInput
                    label={textContent.fileNameLabel}
                    inputName={"file-name"}
                    required={requried}
                    value={fileName}
                    onChange={setFileName}
                  />
                </Grid>
                {error && (
                  <Grid item xs={12} data-testid="error-message">
                    <Alert severity={error.severity}>{error.message}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Grid style={{ width: "50%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="headers-present"
                            data-testid="form-input-headers-present"
                            name="headers-present"
                            checked={headersPresent}
                            disabled={selectedMasterTemplateId !== "0"}
                            onChange={(_, checked) =>
                              setHeadersPresent(checked)
                            }
                          />
                        }
                        label={textContent.hasHeaderRowLabel}
                      />
                      {masterTemplates && (
                        <Grid>
                          <Typography style={{ fontWeight: "bold" }}>
                            {textContent.masterTemplateLabel ||
                              "Master Template"}
                          </Typography>
                          <TextField
                            disabled={masterTemplates.length === 0}
                            select
                            style={{ width: "80%" }}
                            value={selectedMasterTemplateId}
                            name="master-template"
                            data-testid="select-master-template"
                            onChange={(event) =>
                              setSelectedMasterTemplateId(event.target.value)
                            }
                          >
                            <MenuItem value={"0"}>
                              {textContent.templateDefaultOption ||
                                "Select a Template..."}
                            </MenuItem>
                            {masterTemplates.map((template) => (
                              <MenuItem
                                key={`master_template_${template.id}`}
                                value={template.id}
                              >
                                {template.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                    </Grid>
                    <Button
                      id="submit-button"
                      data-testid="form-input-submit-button"
                      variant="contained"
                      type="submit"
                      className={`${classes.btnSubmit} ${
                        masterTemplates ? classes.btnWithTemplate : ""
                      }`}
                      endIcon={<CloudUploadIcon />}
                      disabled={file === null || isSubmitDisabled}
                    >
                      {textContent.submitButton}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </form>
  );
};

export default FileUploadForm;
