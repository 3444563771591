import { Country } from "./types";

export const BE: Country = { code: "BE", aliases: ["belgium"] };
export const AT: Country = { code: "AT", aliases: ["austria"] };
export const DE: Country = { code: "DE", aliases: ["germany"] };
export const DK: Country = { code: "DK", aliases: ["denmark", "scandinavia"] };
export const FR: Country = { code: "FR", aliases: ["france"] };
export const IE: Country = { code: "IE", aliases: ["ireland"] };
export const IT: Country = { code: "IT", aliases: ["italy"] };
export const JP: Country = { code: "JP", aliases: ["japan"] };
export const NL: Country = { code: "NL", aliases: ["netherlands", "holland"] };
export const NO: Country = { code: "NO", aliases: ["norway", "scandinavia"] };
export const SE: Country = { code: "SE", aliases: ["sweden", "scandinavia"] };
export const US: Country = {
  code: "US",
  aliases: ["united states", "america", "usa"],
};
export const CA: Country = { code: "CA", aliases: ["canada"] };
export const GB: Country = {
  code: "GB",
  aliases: [
    "UK",
    "united kingdom",
    "great britain",
    "england",
    "scotland",
    "northern ireland",
    "wales",
  ],
};
export const WW: Country = {
  aliases: ["international"],
  code: "WW",
};

export const countries = [
  AT,
  BE,
  DE,
  DK,
  FR,
  IE,
  IT,
  JP,
  NL,
  NO,
  SE,
  US,
  CA,
  GB,
  WW,
];
