import React, { useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import {
  makeStyles,
  createStyles,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import FileIcon from "./FileIcon";
import { Alert } from "@material-ui/lab";

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const appliedUnitIndex =
    unitIndex >= sizes.length ? sizes.length - 1 : unitIndex;

  return (
    parseFloat((bytes / Math.pow(k, appliedUnitIndex)).toFixed(dm)) +
    " " +
    sizes[appliedUnitIndex]
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropArea: {
      border: `1px dashed ${theme.palette.info.main}`,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        borderColor: theme.palette.info.dark,
      },
    },
    dropAreaFocused: {
      borderStyle: "solid",
      borderColor: theme.palette.secondary.main,
    },
    instructions: {
      marginBottom: theme.spacing(2),
    },
    selectedFile: {
      marginTop: theme.spacing(2),
    },
    clearFile: {
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
  })
);

export interface FileInputProps {
  acceptedFileTypes?: string[];
  maxFileSizeBytes?: number;
  file: File | null;
  setFile(file: File | null): void;
  onFileChange?: (file: File | null) => void;
  id?: string;
  textContent: {
    uploadInstructions: string;
    uploadFileExtensions: string;
    uploadFileLimit: string;
    selectedFileName: string;
    selectedFileSize: string;
    errorInvalidType: string;
    errorFileTooLarge: string;
    errorTooManyFiles: string;
  };
}

const FileInput: React.FC<FileInputProps> = ({
  acceptedFileTypes,
  maxFileSizeBytes,
  file,
  setFile,
  onFileChange = () => {},
  id,
  textContent,
}) => {
  const classes = useStyles();
  const [isDropAreaFocused, setIsDropAreaFocused] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const handleDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    setIsDropAreaFocused(false);
    if (acceptedFiles.length + rejectedFiles.length > 1) {
      setErrorCode("file-count");
    } else if (rejectedFiles.length) {
      setErrorCode(rejectedFiles[0].errors[0].code);
    } else {
      setFile(acceptedFiles[0]);
      onFileChange(acceptedFiles[0]);
      setErrorCode(null);
    }
  };

  const errorCodeToText = {
    "file-invalid-type": textContent.errorInvalidType,
    "file-count": textContent.errorTooManyFiles,
    "file-too-large": textContent.errorFileTooLarge,
  };

  const toErrorText = (errorCode: string): string =>
    errorCodeToText[errorCode] || "File upload error.";

  return (
    <React.Fragment>
      <Dropzone
        onDrop={handleDrop}
        onDragEnter={() => setIsDropAreaFocused(true)}
        onDragLeave={() => setIsDropAreaFocused(false)}
        maxSize={maxFileSizeBytes}
        accept={acceptedFileTypes}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={`${classes.dropArea} ${
              isDropAreaFocused && classes.dropAreaFocused
            }`}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              multiple={false}
              data-testid={"file-upload-input"}
              id={id}
            />
            <Typography className={classes.instructions}>
              {textContent.uploadInstructions}
            </Typography>
            {acceptedFileTypes?.length ? (
              <Typography>
                {textContent.uploadFileExtensions}{" "}
                {acceptedFileTypes.join(", ")}
              </Typography>
            ) : null}
            {maxFileSizeBytes && (
              <Typography>
                {textContent.uploadFileLimit} {formatBytes(maxFileSizeBytes)}
              </Typography>
            )}
          </div>
        )}
      </Dropzone>
      {file && (
        <div
          className={classes.selectedFile}
          data-testid={"file-upload-selected-file"}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={2}>
              <FileIcon extension={file.name.split(".").pop()} size={0.75} />
            </Grid>
            <Grid item xs={9}>
              <Typography>
                {textContent.selectedFileName} {file.name}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {textContent.selectedFileSize} {formatBytes(file.size)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.clearFile}
                onClick={() => setFile(null)}
                data-testid="clear-file-icon"
              />
            </Grid>
          </Grid>
        </div>
      )}
      {errorCode && (
        <Alert className={classes.errorMsg} severity="error">
          {toErrorText(errorCode)}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FileInput;
