import React, { useState } from "react";
import Item from "./Item";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  GridSize,
  Hidden,
  Button,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
      width: "100%",
    },
    hideItem: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

interface FlowchartProps {
  items: {
    title: string | JSX.Element;
    description: string | JSX.Element;
  }[];
}

const Flowchart: React.FC<FlowchartProps> = ({ items, ...props }) => {
  const classes = useStyles();
  const [shownNumber, setShownNumber] = useState(1);

  const numberOfItems = items.length;
  const nearestValidNumber = Math.ceil(12 / numberOfItems) as GridSize;

  const checkIfHidden = (num: number) => shownNumber !== num;

  return (
    <div {...props}>
      <div className={classes.box}>
        <Hidden mdUp>
          <Button
            data-testid="mobile-left-button"
            onClick={() => setShownNumber(shownNumber - 1)}
            disabled={shownNumber === 1}
          >
            <ArrowBackIosIcon />
          </Button>
        </Hidden>
        {items.map((_, index) => (
          <Item
            key={index}
            value={index + 1}
            isStart={index === 0}
            isEnd={index === numberOfItems - 1}
            isHidden={checkIfHidden(index + 1)}
          />
        ))}
        <Hidden mdUp>
          <Button
            data-testid="mobile-right-button"
            onClick={() => setShownNumber(shownNumber + 1)}
            disabled={shownNumber === numberOfItems}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Hidden>
      </div>
      <Grid container justify={"space-evenly"} className={classes.box}>
        {items.map((item, index) => {
          return (
            <Grid
              data-testid={`flowchart-text-${index + 1}`}
              item
              md={nearestValidNumber}
              xs={12}
              key={index}
              className={
                checkIfHidden(index + 1) ? classes.hideItem : undefined
              }
            >
              <Typography
                variant="h6"
                component="p"
                align="center"
                data-testid={`flowchart-title-${index + 1}`}
              >
                {item.title}
              </Typography>
              <Typography
                paragraph
                align="center"
                data-testid={`flowchart-description-${index + 1}`}
              >
                {item.description}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Flowchart;
