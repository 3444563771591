import { Country, FavouritesText } from "./types";
import * as country from "./countryObjects";
import { intersection, difference } from "lodash";

const getFavouriteCountries = (
  userCountryCode: string,
  permittedCountries: Country[]
) => {
  const getPermittedFavourites = (favourites: Country[]) =>
    intersection(favourites, permittedCountries);

  switch (userCountryCode) {
    case "US":
      return getPermittedFavourites([country.US, country.CA]);
    case "BE":
      return getPermittedFavourites([
        country.BE,
        country.DE,
        country.FR,
        country.IE,
        country.IT,
        country.JP,
        country.NL,
        country.NO,
        country.SE,
        country.GB,
        country.US,
      ]);
    case "CA":
      return getPermittedFavourites([country.CA, country.US]);
    case "DE":
      return getPermittedFavourites([
        country.DE,
        country.BE,
        country.DK,
        country.FR,
        country.NL,
      ]);
    case "GB":
      return getPermittedFavourites([country.GB, country.IE]);
    case "IE":
      return getPermittedFavourites([country.IE, country.GB]);
    default:
      const userCountry = permittedCountries.find(
        (country) => country.code === userCountryCode
      );
      return userCountry ? [userCountry] : [];
  }
};

export const getDropDownCountries = (
  countryCode: string,
  countries: Country[],
  favouritesText: FavouritesText
) => {
  const favoriteCountries = getFavouriteCountries(
    countryCode,
    countries
  ) as Country[];
  const otherCountries = difference(
    countries,
    favoriteCountries
  ).map((country) => ({ ...country, type: favouritesText.others }));

  return [
    { ...country.WW, type: favouritesText.favourites },
    ...favoriteCountries.map((country) => ({
      ...country,
      type: favouritesText.favourites,
    })),
    ...otherCountries,
  ];
};
