import React from "react";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as EmptyFlag } from "../../assets/flag-alt.svg";
import PublicIcon from "@material-ui/icons/Public";
import { countriesWithFlags } from "./countriesWithFlags";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      height: (size) => `${size}px`,
      width: (size) => `${size}px`,
    },
  })
);

interface FlagIconProps {
  size: number;
  countryCode?: string;
  fallback?: "nothing" | "international";
}

const FlagIcon: React.FC<FlagIconProps> = ({
  countryCode = "unknown",
  size,
  fallback,
}) => {
  const classes = useStyles(size);
  let formattedCountry = countryCode.toUpperCase();

  if (formattedCountry === "UK") {
    formattedCountry = "GB";
  }

  const getFlagIcon = () => {
    if (countriesWithFlags.includes(formattedCountry)) {
      return (
        <img
          className={classes.icon}
          src={`https://assets.creditsafe.com/upp/flags/${formattedCountry}.png`}
          alt={formattedCountry}
          data-testid={`${formattedCountry}-flag`}
        />
      );
    } else if (formattedCountry === "WW" || fallback === "international") {
      return (
        <PublicIcon
          className={classes.icon}
          data-testid="flag-icon-international"
        />
      );
    } else {
      return (
        <EmptyFlag
          className={classes.icon}
          data-testid="flag-icon-no-country"
        />
      );
    }
  };

  return (
    <Grid container alignItems={"center"}>
      {getFlagIcon()}
    </Grid>
  );
};
export default FlagIcon;
