import React from "react";
import { Typography, makeStyles, Backdrop, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "unset",
    flexDirection: "column",
  },
  image: {
    width: theme.spacing(60),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const SiteUnavailable: React.FC<{
  title: string;
  description: string;
  image: string;
  button: string;
  action: string;
}> = ({ title, description, image, button, action }) => {
  const classes = useStyles();
  const descriptionLines = description.split("\r\n");

  return (
    <Backdrop open classes={{ root: classes.backdrop }}>
      <img src={image} alt="under-construction" className={classes.image} />
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {descriptionLines.map((line, index) => (
        <Typography key={index}>{line}</Typography>
      ))}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        data-testid="return-home"
        onClick={() => (window.location.href = action)}
      >
        {button}
      </Button>
    </Backdrop>
  );
};

export default SiteUnavailable;
