import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Divider,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: theme.palette.grey[500],
      width: "calc(50% - 37.5px)",
    },
    flowchartNumber: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
      border: `${theme.palette.grey[500]} 1px solid`,
    },
    startItem: {
      marginLeft: "calc(50% - 37.5px)",
      backgroundColor: theme.palette.grey[200],
    },
    endItem: {
      marginRight: "calc(50% - 37.5px)",
    },
    flowchartItem: {
      margin: "auto",
      width: "50%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    hideItem: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

interface ItemProps {
  value: number;
  isStart: boolean;
  isEnd: boolean;
  isHidden: boolean;
}
const Item: React.FC<ItemProps> = ({ value, isStart, isEnd, isHidden }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.flowchartItem} ${isHidden && classes.hideItem}`}
      data-testid={`flowchart-item-${value}`}
    >
      {!isStart && (
        <Divider
          className={classes.divider}
          data-testid={`line-1-item-${value}`}
        />
      )}
      <Paper
        className={`${classes.paper} ${isStart && classes.startItem} ${
          isEnd && classes.endItem
        }`}
      >
        <Typography
          variant="h6"
          component="p"
          className={classes.flowchartNumber}
          data-testid={`flowchart-number-${value}`}
        >
          {value}
        </Typography>
      </Paper>
      {!isEnd && (
        <Divider
          className={classes.divider}
          data-testid={`line-2-item-${value}`}
        />
      )}
    </div>
  );
};

export default Item;
