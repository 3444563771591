import { CountryMapping, Mapping } from "./types";

export const automappedHeaders: {
  [key: string]: string;
} = {
  SAFE_NUMBER: "companyId",
  SAFENUMBER: "companyId",
  CS_COMPANY_ID: "companyId",
  CSCOMPANYID: "companyId",
  COMPANYID: "companyId",
  ORG_NUMBER: "orgNumber",
  ORGNUMBER: "orgNumber",
  REGNUMBER: "orgNumber",
  REG_NUMBER: "orgNumber",
  NAME: "name",
  COMPANY_NAME: "name",
  COMPANYNAME: "name",
  VATNUMBER: "vatNumber",
  VAT_NUMBER: "vatNumber",
  TAX_CODE: "taxCode",
  TAXCODE: "taxCode",
  TRADING_NAME: "tradingName",
  TRADINGNAME: "tradingName",
  ALIASNAME: "aliasName",
  PREVIOUSNAME: "previousName",
  ALIAS_NAME: "aliasName",
  PREVIOUS_NAME_1: "previousName",
  PHONE: "phone",
  TEL_NO: "phone",
  TELNO: "phone",
  EMAIL: "email",
  ADDRESS1: "address1",
  ADDRESS_LINE_1: "address1",
  ADDRESSLINE1: "address1",
  ADDRESS2: "address2",
  ADDRESS_LINE_2: "address2",
  ADDRESSLINE2: "address2",
  ADDRESS3: "address3",
  ADDRESS_LINE_3: "address3",
  ADDRESSLINE3: "address3",
  ADDRESS_LINE_5: "address5",
  ADDRESSLINE5: "address5",
  TOWN: "town",
  CITY: "town",
  ZIP: "postcode",
  POSTCODE: "postcode",
  POST_CODE: "postcode",
  POSTAL_CODE: "postcode",
  POSTALCODE: "postcode",
  REGION: "region",
  STATE: "region",
  PROVINCE: "region",
  COUNTRY_CODE: "country",
  COUNTRYCODE: "country",
  COUNTRY: "country",
};

export const automapHeaders = (mappings: Mapping[], headerRow: string[]) =>
  headerRow.map((headerValue, columnNo) => {
    const mappingsItem = mappings.find((m) => Number(m.value) === columnNo);
    return mappingsItem && mappingsItem.mapping?.length > 0
      ? mappingsItem.mapping
      : automappedHeaders[headerValue.replace(/\s/g, "_").toUpperCase()];
  });

export const generateMappings = (
  simpleMappings: string[],
  selectedCountry?: string
): Mapping[] => {
  const eligibleMappings = getMappingCriteriaByCountry(selectedCountry || "");
  simpleMappings = simpleMappings.filter(
    (c) => !eligibleMappings.disallowedMappings.includes(c)
  );

  let mappingsWithIndexes = simpleMappings.map((simpleMapping, index) => {
    return { mapping: simpleMapping, value: String(index) };
  });

  if (
    selectedCountry &&
    selectedCountry.length > 0 &&
    selectedCountry !== "WW"
  ) {
    return [
      ...mappingsWithIndexes,
      { mapping: "countryCode", value: selectedCountry },
    ];
  } else {
    return mappingsWithIndexes;
  }
};

export const generateSimpleMappings = (
  columns: number,
  savedMappings?: Mapping[]
): { mappings: string[]; error: string | null } => {
  const mappings = new Array(columns).fill("");

  if (!savedMappings) {
    return { mappings, error: null };
  }

  if (isMappingOutOfRange(savedMappings, columns)) {
    return {
      mappings,
      error: "SavedMappingOutOfScope",
    };
  }

  savedMappings.forEach((mapping) => {
    const mappingIndex = Number(mapping.value);
    if (!isNaN(mappingIndex) && mappingIndex >= 0) {
      mappings[mappingIndex] = mapping.mapping;
    }
  });

  return { mappings, error: null };
};

const isMappingOutOfRange = (
  mappings: Mapping[],
  columnCount: number
): boolean => mappings.some((mapping) => Number(mapping.value) >= columnCount);

export const checkForCountryMapping = (mappings: Mapping[]) =>
  mappings.some(
    (x) =>
      (x.mapping === "country" && x.value !== "-1") ||
      (x.mapping === "countryCode" && x.value !== "WW" && x.value !== "")
  ) &&
  mappings.some((x) => x.mapping !== "country" && x.mapping !== "countryCode");

export const checkForMappingValidity = (mappings: Mapping[]) =>
  mappings.filter(
    (x) =>
      x.mapping !== "personalID" &&
      x.mapping !== "personalLimit" &&
      x.mapping !== "other" &&
      x.mapping !== "country" &&
      x.mapping !== "countryCode" &&
      x.mapping !== "" &&
      x.mapping !== undefined &&
      x.mapping !== "select"
  ).length > 0;

export const checkForDuplicatePersonalID = (mappings: Mapping[]) =>
  mappings.filter((x) => x.mapping === "personalID").length > 1;

export const checkForDuplicatePersonalLimit = (mappings: Mapping[]) =>
  mappings.filter((x) => x.mapping === "personalLimit").length > 1;

export const getDuplicateMappings = (mappings: Mapping[]) =>
  mappings
    .filter(
      (m) =>
        m.mapping !== undefined &&
        m.mapping !== "" &&
        m.mapping !== null &&
        m.mapping !== "select"
    )
    .filter(
      (e) =>
        mappings.reduce((a, e) => {
          a[e.mapping] = ++a[e.mapping] || 0;
          return a;
        }, {})[e.mapping]
    );

export const getTranslationKey = (label: string) => {
  const firstDot = label.split("").findIndex((item) => item === ".");
  return label.slice(firstDot + 1);
};

export const getMappingTranslation = (mappingOptions: any, mapping: string) => {
  return getTranslationKey(
    mappingOptions.filter(
      (list: { value: string }) => list.value === mapping
    )[0].label
  );
};

export const checkForDataExists = (
  headersPresent: boolean,
  sample: string[][]
) => {
  if (headersPresent) {
    return sample.length > 1;
  } else {
    return sample.length > 0;
  }
};

export const checkForCountryMappingCriteria = (
  mappings: Mapping[],
  countryMapping: CountryMapping
) => {
  const mappingNames = mappings.map((x) => x.mapping);
  return (
    mappings.filter((x) => x.mapping == "countryCode").length > 0 &&
    mappings.filter((x) => x.mapping == "countryCode")[0].value != "" &&
    mappings
      .filter((x) => x.mapping == "countryCode")[0]
      .value.toLocaleLowerCase() != "ww" &&
    (countryMapping.disallowedMappings.some((x) => mappingNames.includes(x)) ||
      !countryMapping.combinationalMappings.some((x) =>
        x.every((x) => mappingNames.includes(x))
      ))
  );
};
export const getMappingCriteriaByCountry = (countryCode: string) => {
  switch (countryCode === "GB" ? "UK" : countryCode) {
    case "":
    case "WW":
      return {
        combinationalMappings: [],
        disallowedMappings: [],
      };
    case "IT":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["vatNumber"],
          ["taxCode"],
          ["name", "postcode"],
          ["name", "phone"],
        ],
        disallowedMappings: ["country"],
      };
    case "US":
      return {
        combinationalMappings: [
          ["companyId"],
          ["name", "postcode", "address1"],
          ["name", "region"],
          ["name", "phone"],
          [
            "phone",
            "address1",
            "address2",
            "address3",
            "town",
            "postcode",
            "region",
          ],
        ],
        disallowedMappings: ["orgNumber", "vatNumber", "taxCode", "country"],
      };

    case "JP":
    case "CA":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["name", "postcode", "address1"],
          ["name", "region"],
          ["name", "phone"],
        ],
        disallowedMappings: ["vatNumber", "taxCode", "country"],
      };
    case "DE":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber", "name"],
          ["vatNumber"],
          ["name"],
        ],
        disallowedMappings: ["taxCode", "country"],
      };
    case "FR":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["vatNumber"],
          ["name", "postcode"],
          ["name", "phone"],
        ],
        disallowedMappings: ["taxCode", "country", "tradingName", "aliasName"],
      };
    case "BE":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["vatNumber"],
          ["name", "postcode"],
          ["name", "phone"],
        ],
        disallowedMappings: ["taxCode", "country"],
      };
    case "NL":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["name", "postcode"],
          ["name", "phone"],
        ],
        disallowedMappings: ["vatNumber", "taxCode", "country"],
      };
    case "CH":
    case "DK":
    case "ES":
    case "FI":
    case "IE":
    case "LI":
    case "LU":
    case "MX":
    case "NO":
    case "UK":
      return {
        combinationalMappings: [["companyId"], ["orgNumber"], ["name"]],
        disallowedMappings: ["vatNumber", "taxCode", "country"],
      };
    case "AT":
    case "GR":
    case "PL":
    case "SE":
      return {
        combinationalMappings: [
          ["companyId"],
          ["orgNumber"],
          ["vatNumber"],
          ["name"],
        ],
        disallowedMappings: ["taxCode", "country"],
      };
    default:
      return {
        combinationalMappings: [],
        disallowedMappings: ["taxCode", "country"],
      };
  }
};
