import React from "react";
import {
  makeStyles,
  createStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/WarningRounded";
import { FieldSelectorTranslations } from "../Mappings/types";
import { mappingOptions } from "../Mappings/constants";
import { getTranslationKey } from "../Mappings/utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorIcon: {
      fill: theme.palette.error.main,
      marginRight: theme.spacing(2),
    },
    proceedButton: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    switchButton: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    cancelButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    title: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-start",
    },
  })
);

interface IWarningDialog {
  isOpen: boolean;
  warningTitle: string;
  warningText: string;
  switchText?: string;
  switchToolTip?: string;
  proceedText: string;
  cancelText: string;
  remapText?: string;
  switchAction?: () => void;
  proceedAction(): void;
  remapAction?: () => void;
  cancelAction(): void;
  minimumRequiredMappings?: string[][];
  translations?: FieldSelectorTranslations;
  and?: string;
}

const WarningDialog: React.FC<IWarningDialog> = ({
  isOpen,
  warningTitle,
  warningText,
  switchText,
  switchToolTip,
  proceedText,
  cancelText,
  remapText,
  switchAction,
  proceedAction,
  remapAction,
  cancelAction,
  minimumRequiredMappings,
  translations,
  and,
}) => {
  const classes = useStyles();

  const getLabel = (value: string): string => {
    return mappingOptions.filter((list) => list.value == value)[0].label;
  };

  return (
    <Dialog open={isOpen} maxWidth={"lg"}>
      <DialogTitle
        disableTypography
        className={classes.title}
        data-testid="warning-dialog"
      >
        <ErrorIcon className={classes.errorIcon} />
        <Typography variant="h2" data-testid="warning-dialog-title">
          {warningTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="warning-dialog-text">
          {warningText}
          {minimumRequiredMappings && translations && (
            <ul data-testid="warning-dialog-ul">
              {minimumRequiredMappings.map((mappings) => (
                <li>
                  {mappings.map((mapping, index) => (
                    <span data-testid={`warning-data-${mapping}`}>
                      {mappings.length > 1 && index === mappings.length - 1
                        ? " " + and + " "
                        : ""}
                      {translations[getTranslationKey(getLabel(mapping))]}
                      {index < mappings.length - 2 ? ", " : ""}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {switchText && switchAction && (
          <Tooltip title={switchToolTip || ""}>
            <Button
              className={classes.switchButton}
              onClick={switchAction}
              data-testid="warning-dialog-switch-button"
            >
              {switchText}
            </Button>
          </Tooltip>
        )}
        {remapAction && remapText && (
          <Button
            variant="contained"
            color="default"
            onClick={remapAction}
            data-testid="warning-dialog-remap-button"
          >
            {remapText}
          </Button>
        )}
        <Button
          className={classes.proceedButton}
          onClick={proceedAction}
          data-testid="warning-dialog-proceed-button"
        >
          {proceedText}
        </Button>
        <Button
          className={classes.cancelButton}
          data-testid="warning-dialog-cancel-button"
          onClick={cancelAction}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
