import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { Mapping, MappingTranslations } from "./types";
import MappingsTable from "./MappingsTable";
import { Alert } from "@material-ui/lab";
import SubmitButton from "./SubmitButton";
import WarningDialog from "../WarningDialog";
import {
  automapHeaders,
  generateSimpleMappings,
  generateMappings,
  checkForCountryMapping,
  checkForMappingValidity,
  checkForCountryMappingCriteria,
  checkForDataExists,
  getMappingCriteriaByCountry,
  getDuplicateMappings,
  getMappingTranslation,
} from "./utils";
import { FileMappingStatus } from "../../types/Status";
import { mappingOptions } from "./constants";

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: {
    marginLeft: theme.spacing(2),
  },
}));

/**
 * The mapping 'countryCode' is used if the job is assigned to a particular country and will take the country's code.
 *   e.g. { mapping: "countryCode", value: "FR" }
 *
 * The mapping 'country' is used if the job is a global job and will reference the column where the country codes can be found.
 *   e.g.  { mapping: "country", value: "0" }
 **/

interface IMappings {
  changesMade: boolean;
  dismissSaveMessage: () => void;
  enableSaving: boolean;
  hasHeaders: boolean;
  setHasHeader: (value: boolean) => void;
  leaveAction: () => void;
  mappingsStatus: FileMappingStatus;
  mappingTranslations: MappingTranslations;
  sample: string[][];
  saveJobAction: (storedMappings: Mapping[], headersPresent: boolean) => void;
  setChangesMade: (value: boolean) => void;
  submitJobAction: (storedMappings: Mapping[], headersPresent: boolean) => void;
  storedMappings: Mapping[];
  setStoredMappings: (value: Mapping[]) => void;
  setDuplicateMappings: (value: string[]) => void;
  selectedJobCountry: string;
  isSubmitError?: boolean;
  setIsSubmitError?: (val: boolean) => void;
  submitErrorMessage?: string[];
}

const Mappings: React.FC<IMappings> = ({
  changesMade,
  dismissSaveMessage,
  enableSaving,
  hasHeaders,
  setHasHeader,
  leaveAction,
  mappingsStatus,
  mappingTranslations,
  sample,
  saveJobAction,
  setChangesMade,
  submitJobAction,
  storedMappings,
  setStoredMappings,
  setDuplicateMappings,
  selectedJobCountry,
  isSubmitError,
  setIsSubmitError,
  submitErrorMessage,
  children,
}) => {
  const classes = useStyles();

  const [isSubmitPopupOpen, setIsSubmitPopupOpen] = React.useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [headersPresent, setHeadersPresent] = useState(hasHeaders || false);
  const doesSampleExist = sample.length > 0;
  const numberOfColumns = (doesSampleExist && sample[0].length) || 0;
  const emptyMappings = Array(numberOfColumns).fill("");
  const [displayInvalidMappingsMsg, setDisplayInvalidMappingsMsg] = useState(
    false
  );
  const [isSaveMappings, setIsSaveMappings] = useState(false);

  const [localMappings, setLocalMappings] = useState(
    generateSimpleMappings(numberOfColumns, storedMappings).mappings
  );

  useEffect(() => {
    setHasHeader(headersPresent);
  }, [headersPresent]);

  useEffect(() => {
    setDuplicateMappings(
      getDuplicateMappings(storedMappings).map(
        (dm) =>
          mappingTranslations.fieldSelectorComponent[
            getMappingTranslation(mappingOptions, dm.mapping)
          ]
      )
    );
  }, [storedMappings]);

  useEffect(() => {
    if (
      hasHeaders &&
      (!localMappings ||
        (localMappings.filter(
          (m) => m !== "" && m !== undefined && m !== "select"
        ).length === 0 &&
          storedMappings.length === 0))
    ) {
      setLocalMappings(
        automapHeaders(
          storedMappings.filter((c) => c.mapping !== "countryCode"),
          sample[0]
        )
      );
      setChangesMade(true);
    }
  }, []);

  useEffect(() => {
    if (storedMappings.length > 0)
      setLocalMappings(
        generateSimpleMappings(numberOfColumns, storedMappings).mappings
      );
  }, [storedMappings]);

  const selectedJobCountryUpper = selectedJobCountry.toUpperCase();

  useEffect(() => {
    if (changesMade === true) {
      removeDisableMappingsOnCountryChange(localMappings);
      if (selectedJobCountryUpper !== "WW" && selectedJobCountryUpper !== "") {
        const newMappings = removeCountryFromLocalMappings(localMappings);
        setStoredMappings(
          generateMappings(newMappings, selectedJobCountryUpper)
        );
      } else {
        setStoredMappings(
          generateMappings(localMappings, selectedJobCountryUpper)
        );
      }
      setChangesMade(false);
    }
  }, [changesMade]);

  useEffect(() => {
    if (
      isSaveMappings &&
      !checkForMappingValidity(storedMappings) &&
      getDuplicateMappings(storedMappings).length !== 0
    ) {
      setDisplayInvalidMappingsMsg(true);
    } else {
      setDisplayInvalidMappingsMsg(false);
    }
  }, [storedMappings]);
  const saveJob = () => {
    setIsSaveMappings(true);
    if (
      checkForMappingValidity(storedMappings) &&
      getDuplicateMappings(storedMappings).length === 0 &&
      checkForCountryMapping(storedMappings)
    ) {
      saveJobAction(storedMappings, headersPresent);
      setChangesMade(false);
      setIsSubmitPopupOpen(false);
    } else {
      setDisplayInvalidMappingsMsg(true);
    }
  };
  const submitJob = () => {
    setIsSaveMappings(false);
    if (
      checkForDataExists(headersPresent, sample) &&
      checkForMappingValidity(storedMappings) &&
      checkForCountryMapping(storedMappings) &&
      getDuplicateMappings(storedMappings).length === 0
    ) {
      const eligibleMappings = getMappingCriteriaByCountry(
        selectedJobCountryUpper || ""
      );
      if (!checkForCountryMappingCriteria(storedMappings, eligibleMappings)) {
        submitJobAction(storedMappings, headersPresent);
        setChangesMade(false);
      } else {
        setIsWarningOpen(true);
        setIsSubmitClicked(true);
      }
    } else {
      setDisplayInvalidMappingsMsg(true);
    }
  };

  const removeCountryFromLocalMappings = (localMappings: string[]) => {
    const indexOfCountry = localMappings.findIndex(
      (mapping) => mapping === "country"
    );
    localMappings[indexOfCountry] = "";
    setLocalMappings(localMappings);
    return localMappings;
  };

  const removeDisableMappingsOnCountryChange = (localMappings: string[]) => {
    const eligibleMappings = getMappingCriteriaByCountry(
      selectedJobCountryUpper || ""
    );

    eligibleMappings?.disallowedMappings.forEach((c) => {
      const indexOfCountry = localMappings.findIndex(
        (mapping) => mapping === c
      );
      localMappings[indexOfCountry] = "";
      setLocalMappings(localMappings);
    });
    return localMappings;
  };

  const validations = () => {
    let errorMessage = "";
    const duplicateMappings = getDuplicateMappings(storedMappings);
    if (!checkForDataExists(headersPresent, sample)) {
      errorMessage = mappingTranslations.invalidMappings.missingData;
    } else if (!checkForMappingValidity(storedMappings)) {
      errorMessage = mappingTranslations.invalidMappings.insufficientFields;
    } else if (!checkForCountryMapping(storedMappings)) {
      errorMessage = mappingTranslations.invalidMappings.missingCountry;
    } else if (duplicateMappings.length > 0) {
      errorMessage =
        mappingTranslations.invalidMappings.duplicateMappingErrorMessage +
        mappingTranslations.fieldSelectorComponent[
          getMappingTranslation(mappingOptions, duplicateMappings[0].mapping)
        ] +
        ".";
    }
    return errorMessage;
  };

  return (
    <Card data-testid="mapping-sample-card">
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justify="space-between">
            <Grid item>
              <CardHeader title={mappingTranslations.title} />
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} justify="flex-start">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="headers-present"
                        data-testid="storedMappings-headers-present"
                        name="headers-present"
                        checked={headersPresent}
                        onChange={() => {
                          setHeadersPresent((e) => {
                            setLocalMappings(
                              automapHeaders(
                                storedMappings.filter(
                                  (c) => c.mapping !== "countryCode"
                                ),
                                sample[0]
                              )
                            );
                            return !e;
                          });
                          setChangesMade(true);
                        }}
                      />
                    }
                    label={mappingTranslations.headersPresent}
                  />
                </Grid>
                <Grid item>{children}</Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonStyle}
                    data-testid="storedMappings-cancel-button"
                    onClick={() => {
                      leaveAction();
                      setLocalMappings(emptyMappings);
                    }}
                  >
                    {mappingTranslations.cancel}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    data-testid="clear-storedMappings-button"
                    onClick={() => {
                      setIsWarningOpen(true);
                      setIsSubmitClicked(false);
                    }}
                  >
                    {mappingTranslations.clearMappings}
                  </Button>
                </Grid>
                <Grid item>
                  <SubmitButton
                    translations={mappingTranslations}
                    saveJob={saveJob}
                    submitJob={submitJob}
                    enableSaving={enableSaving}
                    isSubmitPopupOpen={isSubmitPopupOpen}
                    setIsSubmitPopupOpen={setIsSubmitPopupOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <WarningDialog
          isOpen={isWarningOpen}
          warningTitle={
            isSubmitClicked
              ? mappingTranslations.countryMappingsFailedPrompt.title
              : mappingTranslations.clearMappingsPrompt.title
          }
          warningText={
            isSubmitClicked
              ? mappingTranslations.countryMappingsFailedPrompt.content
              : mappingTranslations.clearMappingsPrompt.content
          }
          proceedText={
            isSubmitClicked
              ? mappingTranslations.countryMappingsFailedPrompt.leave
              : mappingTranslations.clearMappingsPrompt.leave
          }
          cancelText={
            isSubmitClicked
              ? mappingTranslations.countryMappingsFailedPrompt.stay
              : mappingTranslations.clearMappingsPrompt.stay
          }
          proceedAction={() => {
            if (isSubmitClicked) {
              submitJobAction(storedMappings, headersPresent);
              setChangesMade(false);
              setIsSubmitClicked(false);
            } else {
              setLocalMappings(emptyMappings);
              setChangesMade(true);
            }
            setIsWarningOpen(false);
          }}
          cancelAction={() => setIsWarningOpen(false)}
          minimumRequiredMappings={
            isSubmitClicked
              ? getMappingCriteriaByCountry(selectedJobCountryUpper || "")
                  .combinationalMappings
              : []
          }
          translations={
            isSubmitClicked
              ? mappingTranslations.fieldSelectorComponent
              : undefined
          }
          and={isSubmitClicked ? mappingTranslations.and : undefined}
        />
        {displayInvalidMappingsMsg &&
          (!checkForDataExists(headersPresent, sample) ||
            !checkForMappingValidity(storedMappings) ||
            !checkForCountryMapping(storedMappings) ||
            getDuplicateMappings(storedMappings).length > 0) && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                onClose={() => setDisplayInvalidMappingsMsg(false)}
                data-testid="job-storedMappings-invalid-storedMappings"
              >
                {validations()}
              </Alert>
            </Grid>
          )}
        {isSubmitError &&
          submitErrorMessage &&
          submitErrorMessage.length > 0 &&
          setIsSubmitError && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                onClose={() => setIsSubmitError(false)}
                data-testid="submit-error-message"
                style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}
              >
                {submitErrorMessage.length === 1 && (
                  <Typography>{submitErrorMessage[0]}</Typography>
                )}
                {submitErrorMessage.length > 1 && (
                  <Typography>
                    Submit job failed with following validations:
                    <ul style={{ margin: 0 }}>
                      {submitErrorMessage.map((c) => (
                        <li>{c}</li>
                      ))}
                    </ul>
                  </Typography>
                )}
              </Alert>
            </Grid>
          )}
        {mappingsStatus === FileMappingStatus.saved && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              onClose={dismissSaveMessage}
              data-testid="job-storedMappings-storedMappings-saved"
            >
              {mappingTranslations.statusMessages.mappingsSaved}
            </Alert>
          </Grid>
        )}
        {mappingsStatus === FileMappingStatus.errorSaving && (
          <Grid item xs={12}>
            <Alert
              severity="error"
              onClose={dismissSaveMessage}
              data-testid="job-storedMappings-storedMappings-save-error"
            >
              {mappingTranslations.statusMessages.mappingsSaveError}
            </Alert>
          </Grid>
        )}
      </Grid>

      <MappingsTable
        setChangesMade={setChangesMade}
        translations={mappingTranslations.fieldSelectorComponent}
        headers={
          doesSampleExist
            ? sample[0].map((value, index) =>
                headersPresent
                  ? value
                  : `${mappingTranslations.column} ${index + 1}`
              )
            : []
        }
        sample={sample.slice(
          0 + Number(headersPresent),
          25 + Number(headersPresent)
        )}
        mappedColumns={localMappings.map((x) => (x === undefined ? "" : x))}
        updateMappings={setLocalMappings}
        selectedCountry={selectedJobCountryUpper}
      />
    </Card>
  );
};

export default Mappings;
