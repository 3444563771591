import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
} from "@material-ui/core";

interface IFormInput {
  label: string;
  inputName: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  type?: string;
  maxLenght?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledInput: {
      backgroundColor: theme.palette.grey[300],
      "&>input": { color: theme.palette.text.primary },
    },
    label: {
      display: "block",
      fontWeight: 600,
    },
  })
);

const FormInput: React.FC<IFormInput> = ({
  label,
  inputName,
  defaultValue,
  disabled,
  required,
  value,
  onChange,
  type = "text",
  maxLenght,
}) => {
  const classes = useStyles();
  const inputClass = disabled ? classes.disabledInput : "";
  const [stateValue, setStateValue] = useState(defaultValue || "");
  useEffect(() => {
    setStateValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <React.Fragment>
      <Typography
        className={classes.label}
        component="label"
        htmlFor={inputName}
        gutterBottom
      >
        {label}
      </Typography>
      <TextField
        id={inputName}
        name={inputName}
        InputProps={{ classes: { root: inputClass } }}
        inputProps={{
          "data-testid": `form-input-${inputName}`,
          maxlength: maxLenght,
        }}
        variant={"outlined"}
        size="small"
        onChange={(e) =>
          onChange ? onChange(e.target.value) : setStateValue(e.target.value)
        }
        value={value || stateValue}
        required={required}
        disabled={disabled}
        type={type}
        fullWidth
      />
    </React.Fragment>
  );
};

FormInput.defaultProps = {
  disabled: false,
  required: false,
};

export default FormInput;
