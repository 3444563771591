import React from "react";
import {
  TextField,
  Theme,
  createStyles,
  makeStyles,
  Typography,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getDropDownCountries } from "./utils";
import { Country, CountryDropDownProps } from "./types";
import { countries as countryDetails } from "./countryObjects";
import FlagIcon from "../FlagIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      ".MuiAutocomplete-endAdornment": {
        top: "4px",
      },
    },
    optionText: {
      fontSize: "1rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    groupLabel: {
      fontSize: "1.2rem",
      lineHeight: "1.6rem",
    },
    textField: {
      width: "100%",
      minWidth: theme.spacing(22),
    },
    tagSizeSmall: {
      height: "19px",
      fontSize: "1.1rem",
    },
  })
);

const CountryDropDownMenu: React.FC<CountryDropDownProps> = ({
  selectedCountryCodes,
  setSelectedCountryCodes,
  countries,
  userCountryCode,
  titleText,
  wwText,
  favouritesText,
  required,
  inline,
  multiple,
  limitTags,
  setChangesMade,
  inputClass,
  showFlag,
}) => {
  const classes = useStyles();
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<string>(
    "WW"
  );
  const getCountriesByCode = (countryCodes: string[]): Country[] =>
    countryDetails.filter((country) =>
      countryCodes.some((countryCode) => countryCode === country.code)
    );

  const allCountries = getCountriesByCode(Object.keys(countries));

  const countryList = favouritesText
    ? getDropDownCountries(userCountryCode, allCountries, favouritesText)
    : allCountries;

  const selectedCountry = selectedCountryCodes
    ? countryList.find(
        (x) => x.code.toLowerCase() === selectedCountryCodes.toLowerCase()
      )
    : null;

  const selectedCountries = countryList.filter((x) =>
    selectedCountryCodes.split(",").includes(x.code)
  );

  const getOptionName = (code: string) =>
    code.toUpperCase() === "WW" ? wwText : countries[code];

  const autoCompleteInput = (params: AutocompleteRenderInputParams) => {
    if (showFlag) {
      return (
        <TextField
          {...params}
          variant="outlined"
          id="country-name"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FlagIcon countryCode={selectedCountryCode} size={20} />
              </InputAdornment>
            ),
          }}
          data-testid="country-dropdown-text-field"
          className={`${classes.textField} ${inputClass}`}
          name="countryCode"
          label={inline ? titleText : ""}
          required={required}
        />
      );
    } else {
      return (
        <TextField
          {...params}
          variant="outlined"
          id="country-name"
          InputProps={{
            ...params.InputProps,
          }}
          data-testid="country-dropdown-text-field"
          className={`${classes.textField} ${inputClass}`}
          name="countryCode"
          label={inline ? titleText : ""}
          required={required}
        />
      );
    }
  };

  const autoCompleteRenderOptions = (option: Country) => (
    <Grid container alignItems="center" justify="flex-start" spacing={3}>
      <Grid item>
        <FlagIcon countryCode={option.code} size={20} />
      </Grid>
      <Grid item>
        <Typography className={classes.optionText} variant="body2">
          {getOptionName(option.code)}
        </Typography>
      </Grid>
    </Grid>
  );

  const autoCompleteFilterOptions = createFilterOptions({
    stringify: (option: Country) => `${option.code} ${option.aliases}`,
  });

  const commonprops = {
    classes: {
      groupLabel: classes.groupLabel,
      tagSizeSmall: classes.tagSizeSmall,
    },
    groupBy: (option: Country) => option.type || "",
    openOnFocus: true,
    autoHighlight: true,
    id: "tags-standard",
    options: countryList,
    getOptionLabel: (option: Country) => {
      setSelectedCountryCode(option.code);
      return getOptionName(option.code);
    },
    "data-testid": "country-drop-down-value-selection",
    renderInput: autoCompleteInput,
    renderOption: autoCompleteRenderOptions,
    filterOptions: autoCompleteFilterOptions,
  };

  if (multiple) {
    return (
      <Autocomplete
        {...commonprops}
        multiple
        size="small"
        disableCloseOnSelect
        filterSelectedOptions
        limitTags={limitTags}
        value={selectedCountries}
        onChange={(_: unknown, countries: Country[]) => {
          setSelectedCountryCodes(
            countries?.map((o: Country) => o.code).join(",")
          );
          setChangesMade && setChangesMade(true);
        }}
      />
    );
  } else {
    return (
      <Autocomplete
        {...commonprops}
        value={selectedCountry}
        onChange={(_: unknown, option: Country | null) => {
          const code = option ? option.code : "";
          if (code.length > 1) {
            setSelectedCountryCodes(code);
            setChangesMade && setChangesMade(true);
          }
        }}
      />
    );
  }
};

export default CountryDropDownMenu;
