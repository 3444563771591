import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

interface StyleProps {
  size: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    fileContainer: {
      position: "relative",
    },
    fileSuccessColor: {
      color: theme.palette.success.dark,
    },
    fileIcon: ({ size }) => ({
      fontSize: theme.spacing(size * 8),
    }),
    fileExtension: ({ size }) => ({
      position: "absolute",
      bottom: theme.spacing(size * 2),
      left: theme.spacing(size * 2.25),
      fontSize: theme.spacing(size * 1.25),
      margin: theme.spacing(0),
    }),
  })
);

interface FileIconProps {
  extension?: string;
  size?: number;
  isPrimaryColor?: boolean;
}

const FileIcon: React.FC<FileIconProps> = ({
  extension,
  size = 1,
  isPrimaryColor = false,
}) => {
  const classes = useStyles({ size });
  const dataTestId = extension ? `file-icon-${extension}` : "file-icon";
  const fileItemClass = `${classes.fileContainer} ${
    !isPrimaryColor && classes.fileSuccessColor
  }`;
  return (
    <div className={fileItemClass} data-testid={dataTestId}>
      <InsertDriveFileOutlinedIcon className={classes.fileIcon} />
      <p className={classes.fileExtension}>
        {extension?.toUpperCase().slice(0, 4)}
      </p>
    </div>
  );
};

export default FileIcon;
