import React from "react";
import {
  makeStyles,
  ListSubheader,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
} from "@material-ui/core";
import { mappingOptions } from "./constants";
import { FieldSelectorTranslations } from "./types";
import { getMappingCriteriaByCountry, getTranslationKey } from "./utils";

interface onChange {
  name?: string;
  value: string;
}

interface IFieldSelector {
  value: string;
  dataTestId: string;
  onChange: (event: React.ChangeEvent<onChange>) => void;
  selectedCountry: string;
  translations: FieldSelectorTranslations;
}

interface IMappingsTable {
  headers: string[];
  mappedColumns: string[];
  updateMappings(mappings: string[]): void;
  sample: string[][];
  selectedCountry: string;
  translations: FieldSelectorTranslations;
  setChangesMade: (changesMade: boolean) => void;
}

const useSelectorStyles = makeStyles(() => ({
  subListHeaderStyle: {
    fontWeight: "bold",
    color: "black",
    height: "34px",
  },
  selectWidth: { minWidth: "201px" },
}));

const filterOptions = (
  countryCode: string
): { label: string; value?: string }[] => {
  const eligibleMappings = getMappingCriteriaByCountry(countryCode);
  return mappingOptions.filter((list) =>
    list.value
      ? !eligibleMappings.disallowedMappings.includes(list.value)
      : true
  );
};

const FieldSelector: React.FC<IFieldSelector> = ({
  value,
  dataTestId,
  onChange,
  selectedCountry,
  translations,
}) => {
  const filteredOptions = filterOptions(selectedCountry);
  const classes = useSelectorStyles();

  return (
    <Select
      value={value}
      data-testid={`${dataTestId}-select`}
      inputProps={{ "data-testid": dataTestId }}
      onChange={(e) => onChange(e as React.ChangeEvent<onChange>)}
      fullWidth
      className={classes.selectWidth}
    >
      {filteredOptions.map((item, index) =>
        item.value ? (
          <MenuItem
            key={index}
            value={String(item.value)}
            data-testid={`item-${index}`}
          >
            <Typography variant="body2">
              {translations[getTranslationKey(item.label)]}
            </Typography>
          </MenuItem>
        ) : (
          <ListSubheader
            key={index}
            data-testid={`item-${index}`}
            className={classes.subListHeaderStyle}
            disableSticky
          >
            {translations[getTranslationKey(item.label)]}
          </ListSubheader>
        )
      )}
    </Select>
  );
};

const useTableStyles = makeStyles(() => ({
  tableHeader: {
    overflowX: "auto",
    maxWidth: "100%",
    transform: `rotateX(180deg)`,
    "&::-webkit-scrollbar": {
      height: "13px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#d6e3d6",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#899689",
      borderRadius: 7,
    },
  },
  table: {
    transform: `rotateX(180deg)`,
  },

  tableCell: {
    width: "235px",
  },
  header: { fontWeight: 800 },
}));

const MappingsTable: React.FC<IMappingsTable> = ({
  headers,
  mappedColumns,
  updateMappings,
  sample,
  selectedCountry,
  translations,
  setChangesMade,
}) => {
  const classes = useTableStyles();

  const insertValueAtIndex = (index: number, newVal: string, arr: string[]) => {
    const preVal = arr.slice(0, index);
    const postVal = arr.slice(index + 1);
    return [...preVal, newVal, ...postVal];
  };

  return (
    <TableContainer className={classes.tableHeader}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return (
                <TableCell
                  key={index}
                  className={`${classes.tableCell} `}
                  data-testid={`mapping-column-header-${index}`}
                >
                  <Typography variant="body1" className={classes.header}>
                    {header}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            {mappedColumns.map((value, index) => {
              return (
                <TableCell key={index} className={classes.tableCell}>
                  <FieldSelector
                    value={value || "select"}
                    translations={translations}
                    dataTestId={`FieldSelector${index}`}
                    onChange={(e) => {
                      setChangesMade(true);
                      updateMappings(
                        insertValueAtIndex(index, e.target.value, mappedColumns)
                      );
                    }}
                    selectedCountry={selectedCountry}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sample.map((subarray, rowIndex) => {
            return (
              <TableRow key={rowIndex}>
                {subarray.map((value, cellIndex) => {
                  return (
                    <TableCell className={classes.tableCell} key={cellIndex}>
                      {value.length > 0 ? value : "-"}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MappingsTable;
